<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div>
        <div class="p-card-field">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right">Công ty <span style="color: red">*</span></label>
            <div class="p-col">
              <MultiSelect class="multiselect input_text border_color padding select-mobi" v-on:input="change_company" id="group_channel_company" v-model="selectedCompany" :options="company" optionLabel="code" optionValue="id"  placeholder="Chọn " />
              <span slot="no-options" @click="$refs.select.open = false">
              </span>
              <input class="input_tmp_validator" id="vali_company">
            </div>
          </div>
          <div class="p-card-field">
            <label class="p-col-fixed txt-right" id="tet">Kênh phân phối <span style="color: red">*</span></label>
            <div class="p-col">
              <Dropdown id="selectChannel" class="input_text border_color padding channel_pad select-mobi" v-model="selectedChannel" :options="channel" optionLabel="name" optionValue="id" placeholder="Chọn" />
              <span slot="no-options" @click="$refs.select.open = false">
              </span>
              <input class="input_tmp_validator" id="vali_channel">
            </div>
          </div>
          <div class="p-card-field">
            <label class="p-col-fixed txt-right">Tên nhóm kênh <span style="color: red">*</span></label>
            <div class="p-col">
              <Textarea :autoResize="true" rows="1" cols="30" maxlength="255" v-model="group_channel.name"  id="name" type="text" class="p-col-width border_color padding text-area" placeholder="Nhập " />
            </div>
          </div>
          <div class="p-card-field">
            <label class="p-col-fixed txt-right">Mã nhóm kênh <span style="color: red">*</span></label>
            <div class="p-col">
              <InputText maxlength="50" required v-model="group_channel.code"  id="code" type="text" class="p-col-width input_text border_color padding" placeholder="Nhập " />
            </div>
          </div>
          <div class="p-card-field">
            <div class="p-col">
              <Checkbox  v-model="group_channel.checked" :binary="true"/>
              <label class="p-col-fixed txt-right text">Hiển thị</label>
            </div>
          </div>
        </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>

</template>
<script>
import DataServices from '@/core/DataServices'
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {

      group_channel:{
        name: '',
        code:'',
        checked: true,
      },
      selectedCompany: null,
      selectedChannel: null,
      company: [],
      channel: [],
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {
console.log("kmkkmkmkmkm")
    // đổ dữ liệu khi edit
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
// lấy data đổ vào input
//       this.group_channel = this.$commonFuction.convertJsonObject(this.model);
      this.group_channel.name = this.model.name
      this.group_channel.code = this.model.code
      this.group_channel.checked = this.model.active
    }



    // đổ data ra select data company
    console.log("gqLQueryList_company")
      var gqLQueryList_company = DataServices.getList('company');
console.log("gqLQueryList_company",gqLQueryList_company)
      var resdata_company = await this.$apollo.mutate({
        mutation: gqLQueryList_company,
        variables: {
          orderBy: {created_at: 'desc'}
        }
      });
    this.company = resdata_company.data['company'];
    console.log("gqLQueryList_company",this.company)








    // đổ data company ra khi edit cong ty va channel
    console.log(this.mode,"modeeeeee")
    if (this.mode == 'edit') {
    console.log("array11122")
      var list_group_channel_edit = this.$commonFuction.convertJsonObject(this.model);
    var list_channel_company = (list_group_channel_edit.group_channel_companies) ? list_group_channel_edit.group_channel_companies:'';
    var array = [];
    console.log("array11122",list_channel_company)
    for (var i = 0; i < list_channel_company.length; i++) {
      array.push(list_channel_company[i].company_id)

      // }
      this.selectedCompany = array;
    }

    var selected_channel = (list_group_channel_edit.channel) ? list_group_channel_edit.channel:'';
      console.log("array111ewew",selected_channel)
    this.selectedChannel = selected_channel.id
    console.log(this.selectedChannel,"selectedchanenl")










      // đổ data ra select data channel theo id cong ty
      var id = []
      id  =  this.selectedCompany

      console.log("sáiaiiwq",id)
      let variables
      if ( id)
      {
        variables = {
          'where_key': {
            'company_id': {'_in': id}
          }
        }
      }
      console.log("sahshawyq",variables)

      var list_channel_company_edit = DataServices.getList('channel_company');
      var resdata_channel_company = await this.$apollo.mutate({
        mutation: list_channel_company_edit,
        variables: variables
      });
      var channel_company = resdata_channel_company.data['channel_company'];
      console.log("saksauuwqw21wq",this.channel)
      var channel_id = []
      for (var j = 0 ; j < channel_company.length ; j++)
      {
        channel_id.push(channel_company[j].channel_id)
      }

      variables = {
        'where_key': {
          'id': {'_in': channel_id}
        }
      }
      var list_channel = DataServices.getList('channel');
      var resdata_channel = await this.$apollo.mutate({
        mutation: list_channel,
        variables: variables
      });
      var channel = resdata_channel.data['channel'];
      this.channel = channel
      console.log("cocaigidau",this.channel)

    }
  },
  methods: {

    async change_company() {
      var id = []
       id  =  this.selectedCompany

      console.log("sáiaiiwq",id)
      let variables
      if ( id)
      {
        variables = {
          'where_key': {
            'company_id': {'_in': id},
            'deleted': {'_eq': false}
          }
        }
      }
      console.log("sahshawyq",variables)

      var list_channel_company = DataServices.getList('channel_company');
      var resdata_channel_company = await this.$apollo.mutate({
        mutation: list_channel_company,
        variables: variables
      });
      var channel_company = resdata_channel_company.data['channel_company'];
      console.log("saksauuwqw21wq",this.channel)
      var channel_id = []
      for (var i = 0 ; i < channel_company.length ; i++)
      {
        channel_id.push(channel_company[i].channel_id)
      }

      variables = {
        'where_key': {
          'id': {'_in': channel_id},
          'deleted': {'_eq': false}
        }
      }
      var list_channel = DataServices.getList('channel');
      var resdata_channel = await this.$apollo.mutate({
        mutation: list_channel,
        variables: variables
      });
      var channel = resdata_channel.data['channel'];
      this.channel = channel
      console.log("cocaigidau",this.channel)





    },
    backToList() {
      this.$emit('back');
    },

    async validateData() {
      this.validationErrors = {};

      let company_add = document.getElementById("vali_company");
      if (this.$commonFuction.isEmptyObject(this.selectedCompany)){
        company_add.setCustomValidity("Công ty không được để trống!");
      }else {
        company_add.setCustomValidity("");
      }


      let channel_add = document.getElementById("vali_channel");
      if (this.$commonFuction.isEmptyObject(this.selectedChannel)){
        channel_add.setCustomValidity("Kênh phân phối không được để trống!");
      }else {
        channel_add.setCustomValidity("");
      }


      let name_add = document.getElementById("name");

      console.log(this.group_channel.name, "validate")
      if (this.group_channel.name == undefined || this.group_channel.name == null || this.group_channel.name == ''){
        console.log(this.group_channel.name, "validate" ,name_add)
        name_add.setCustomValidity("Tên không được để trống!");
      }else {
        name_add.setCustomValidity("");
      }

      let code_add = document.getElementById("code");
      if (this.group_channel.code == undefined || this.group_channel.code == null || this.group_channel.code == ''){
        code_add.setCustomValidity("Mã không được để trống!");
      }else {
        code_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){

        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
       console.log("test4")
      console.log("checked",this.channel.checked)

      var company_id = [];
//seleted cong ty

      var list_validate = DataServices.getList('group_channel');
      console.log("gqL",list_validate)
      var res_validate = await this.$apollo.mutate({
        mutation: list_validate,
        variables: {
          where_key: {deleted: {_eq: false}},
          orderBy: {created_at: 'desc'}
        }
      });
      var list_group_channel = res_validate.data['group_channel'];


      console.log("idlagivay",this.group_channel.id,list_group_channel)
      var check_code = ''
      var check_code_new = ''
      if (this.mode == "edit")
      {
        var group_channel_check = this.$commonFuction.convertJsonObject(this.model);
        console.log("wqwsasasasa12",group_channel_check,list_group_channel)

        for (var j = 0 ; j< list_group_channel.length ; j++)
        {
          check_code = list_group_channel[j].code ? list_group_channel[j].code.toLowerCase() :""
          check_code_new = this.group_channel.code ? this.group_channel.code.toLowerCase():""
          if (check_code == check_code_new && list_group_channel[j].id != group_channel_check.id)
          {
            alert("Mã nhóm kênh này đã tồn tại. Vui lòng thử lại")
            return
          }
        }
      }

      if (this.mode == "add")
      {
        console.log("aaaadddd")
        for (var k = 0 ; k< list_group_channel.length ; k++)
        {
          check_code = list_group_channel[k].code ? list_group_channel[k].code.toLowerCase() :""
          check_code_new = this.group_channel.code ? this.group_channel.code.toLowerCase():""
          if (check_code == check_code_new)
          {
            alert("Mã Kênh này đã tồn tại. Vui lòng thử lại")
            return
          }
        }
      }


      var length_company = this.selectedCompany ? this.selectedCompany :[]
      for( var i = 0 ; i < length_company.length ; i++)
      {
        company_id.push(this.selectedCompany[i])
      }
      this.selectedCompany = company_id;

      console.log("check",company_id)


//selected channel
      this.selectedChannel ? this.selectedChannel:0
      if (this.selectedChannel == 0 )
      {
        alert(" Kênh không được trống !!")
        return;
      }

      var channel_id = this.selectedChannel ? this.selectedChannel :''
this.selectedChannel =  channel_id;
      console.log(channel_id,"channelid")

      // console.log(length_channel,"list chaneeel")
      // for( var j = 0 ; j < length_channel.length ; j++)
      // {
      //   channel_id.push(this.selectedChannel[j])
      // }

        var params = {
          name  :this.group_channel.name,
          code:this.group_channel.code,
          company_id:company_id,
          channel_id:channel_id,
          active:this.group_channel.checked
        }
      console.log('saveData', params)
        // console.log("params:",params);
        // console.log("do dai ",this.selectedCompany.length);
        // console.log("selected company",this.selectedCompany[0].id)
        // console.log("company id",params.company_id);
        // console.log(params);
      // var name = (params.name.length) ? params.name.length:0
      // var code = (params.code.length) ? params.code.length:0
      console.log("nasnasasas",name)
console.log("sâsaaa");
        console.log('saveData', params)
        this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}

//style form add-edit
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 498px!important;
  height: 40px!important;
}

.txt-right{
  font-weight: bold;
}
.p-formgrid .fixed-bottom-toolbar{
  border-top: none!important;
}
.text{
  font-weight: normal;
}
.layout-main{
  background: #00000029;
}

.p-button-warning{
  border: 1px solid #C40380 !important;

}
#back{
  color: #C40380 !important;
}
.vs__clear{
  display: none;
}
.input_text{
  width: 744px!important;
  height: 48px!important;
}
.text-area{
  width: 744px!important;
  min-height: 48px;
}
.border_color{
  border: 1px solid #D0D4D9!important;
}
.padding{
  padding:10px!important;
}
.p-multiselect .p-multiselect-label{
  padding: 0px!important;
}
.channel_pad{
  padding: 0px!important;
}
.p-inputtext{
  padding: 10px!important;
}
.p-column-filter p-inputtext p-component{
  width: 100%!important;
}
.p-dropdown-items-wrapper{
  max-width: 744px!important;
}





//responsive
@media screen and (max-width: 600px) {
  .input_text{
    width: 100%!important;
  }
  .text-area{
    width: 100%!important;
  }
  .select-mobi{
    text-align: left!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-multiselect-label{
    text-align: left!important;
  }
}

@media screen and (max-width: 1000px) {
  .input_text{
    width: 100%!important;
  }
  .text-area{
    width: 100%!important;
  }
  .select-mobi{
    text-align: left!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-multiselect-label{
    text-align: left!important;
  }
}



</style>
